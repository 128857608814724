/**
 * Creates and configures the platforms for 1Level in the scene.
 * @param {Phaser.Scene} scene - The Phaser scene.
 * @returns {Phaser.Physics.Arcade.StaticGroup} The group of platforms.
 */
export function createPlatforms2(scene) {
  const platforms = scene.physics.add.staticGroup()

  // pl1 - flying
  platforms.create(869, 350, 'groundFlyingLeft')
  platforms.create(933, 350, 'groundFlyingMiddle')
  platforms.create(997, 350, 'groundFlyingMiddle')
  platforms.create(1061, 350, 'groundFlyingMiddle')
  platforms.create(1125, 350, 'groundFlyingMiddle')
  platforms.create(1189, 350, 'groundFlyingMiddle')
  platforms.create(1243, 350, 'groundFlyingRight')

  // Pl1
  platforms.create(32, 638, 'groundBrown')
  platforms.create(32, 574, 'groundBrown')
  platforms.create(32, 514, 'groundBrownGreenUp')
  platforms.create(96, 638, 'groundBrown')
  platforms.create(96, 574, 'groundBrown')
  platforms.create(96, 514, 'groundBrownGreenUp')
  platforms.create(160, 638, 'groundBrown')
  platforms.create(160, 574, 'groundBrown')
  platforms.create(160, 514, 'groundBrownGreenUp')
  platforms.create(204, 638, 'groundBrown')
  platforms.create(204, 574, 'groundBrown')
  platforms.create(204, 514, 'groundBrownGreenUp')
  platforms.create(268, 638, 'groundBrown')
  platforms.create(268, 574, 'groundBrown')
  platforms.create(268, 514, 'groundBrownGreenUp')
  platforms.create(332, 638, 'groundBrown')
  platforms.create(332, 574, 'groundBrown')
  platforms.create(332, 514, 'groundBrownGreenUp')
  platforms.create(396, 638, 'groundBrown')
  platforms.create(396, 574, 'groundBrown')
  platforms.create(396, 514, 'groundBrownGreenUp')
  platforms.create(460, 638, 'groundBrown')
  platforms.create(460, 574, 'groundBrown')
  platforms.create(460, 514, 'groundBrownGreenUp')
  platforms.create(524, 638, 'groundBrown')
  platforms.create(524, 574, 'groundBrown')
  platforms.create(524, 514, 'groundBrownGreenUp')
  platforms.create(588, 638, 'groundBrown')
  platforms.create(588, 574, 'groundBrown')
  platforms.create(588, 514, 'groundBrownGreenUp')
  platforms.create(652, 638, 'groundBrown')
  platforms.create(652, 574, 'groundBrown')
  platforms.create(652, 514, 'groundBrownGreenUp')
  platforms.create(716, 638, 'groundBrown')
  platforms.create(716, 574, 'groundBrown')
  platforms.create(716, 514, 'groundBrownGreenUp')
  platforms.create(780, 638, 'groundBrown')
  platforms.create(780, 574, 'groundBrown')
  platforms.create(780, 514, 'groundBrownGreenUp')
  platforms.create(844, 638, 'groundBrown')
  platforms.create(844, 574, 'groundBrown')
  platforms.create(844, 514, 'groundBrownGreenUp')
  platforms.create(908, 638, 'groundBrown')
  platforms.create(908, 574, 'groundBrown')
  platforms.create(908, 514, 'groundBrownGreenUp')
  platforms.create(972, 638, 'groundBrown')
  platforms.create(972, 574, 'groundBrown')
  platforms.create(972, 514, 'groundBrownGreenUp')
  platforms.create(1036, 638, 'groundBrown')
  platforms.create(1036, 574, 'groundBrown')
  platforms.create(1036, 514, 'groundBrownGreenUp')
  platforms.create(1100, 638, 'groundBrown')
  platforms.create(1100, 574, 'groundBrown')
  platforms.create(1100, 514, 'groundBrownGreenUp')
  platforms.create(1164, 638, 'groundBrown')
  platforms.create(1164, 574, 'groundBrown')
  platforms.create(1164, 514, 'groundBrownGreenUp')
  platforms.create(1228, 638, 'groundBrown')
  platforms.create(1228, 574, 'groundBrown')
  platforms.create(1228, 514, 'groundBrownGreenUp')
  platforms.create(1292, 638, 'groundBrown')
  platforms.create(1292, 574, 'groundBrown')
  platforms.create(1292, 514, 'groundBrownGreenUp')
  platforms.create(1356, 638, 'groundBrown')
  platforms.create(1356, 574, 'groundBrown')
  platforms.create(1356, 514, 'groundBrownGreenUp')
  platforms.create(1420, 638, 'groundBrown')
  platforms.create(1420, 574, 'groundBrown')
  platforms.create(1420, 514, 'groundBrownGreenUp')
  platforms.create(1484, 638, 'groundBrown')
  platforms.create(1484, 574, 'groundBrown')
  platforms.create(1484, 514, 'groundBrownGreenUp')
  platforms.create(1548, 638, 'groundBrown')
  platforms.create(1548, 574, 'groundBrown')
  platforms.create(1548, 514, 'groundBrownGreenUp')
  platforms.create(1612, 638, 'groundBrown')
  platforms.create(1612, 574, 'groundBrown')
  platforms.create(1612, 514, 'groundBrownGreenUp')
  platforms.create(1676, 638, 'groundBrown')
  platforms.create(1676, 574, 'groundBrown')
  platforms.create(1676, 514, 'groundBrownGreenUp')
  platforms.create(1740, 638, 'groundBrown')
  platforms.create(1740, 574, 'groundBrown')
  platforms.create(1740, 514, 'groundBrownGreenUp')
  // Jump up - wall in front of the character
  platforms.create(1804, 638, 'groundBrown')
  platforms.create(1804, 574, 'groundBrown')
  platforms.create(1804, 514, 'groundBrownGreenUpCurlyUpRight')
  platforms.create(1868, 638, 'groundBrown')
  platforms.create(1868, 574, 'groundBrown')
  platforms.create(1868, 514, 'groundBrownCornerLeft')
  platforms.create(1868, 450, 'groundBrownCurlyLeft') // little shorter 63 (all curly left and right)
  platforms.create(1868, 386, 'groundBrownCurlyLeft')
  platforms.create(1868, 322, 'groundBrownGreenCurlyLeft')
  // Stairs
  platforms.create(1930, 638, 'groundBrown')
  platforms.create(1930, 574, 'groundBrown')
  platforms.create(1930, 514, 'groundBrown')
  platforms.create(1930, 450, 'groundBrown')
  platforms.create(1930, 386, 'groundBrown')
  platforms.create(1930, 322, 'groundBrownGreenUp')
  platforms.create(1992, 638, 'groundBrown')
  platforms.create(1992, 574, 'groundBrown')
  platforms.create(1992, 514, 'groundBrown')
  platforms.create(1992, 450, 'groundBrown')
  platforms.create(1992, 386, 'groundBrownCornerRight')
  platforms.create(1992, 322, 'groundBrownGreenCurlyRight')
  // 2nd stair
  platforms.create(2056, 638, 'groundBrown')
  platforms.create(2056, 574, 'groundBrown')
  platforms.create(2056, 514, 'groundBrown')
  platforms.create(2056, 450, 'groundBrown')
  platforms.create(2056, 386, 'groundBrownGreenUpCurlyUpLeft')
  platforms.create(2120, 638, 'groundBrown')
  platforms.create(2120, 574, 'groundBrown')
  platforms.create(2120, 514, 'groundBrown')
  platforms.create(2120, 450, 'groundBrown')
  platforms.create(2120, 386, 'groundBrownGreenUp')
  platforms.create(2182, 638, 'groundBrown')
  platforms.create(2182, 574, 'groundBrown')
  platforms.create(2182, 514, 'groundBrown')
  platforms.create(2182, 450, 'groundBrownCornerRight')
  platforms.create(2182, 386, 'groundBrownGreenCurlyRight')
  // 3rd stair
  platforms.create(2246, 638, 'groundBrown')
  platforms.create(2246, 574, 'groundBrown')
  platforms.create(2246, 514, 'groundBrown')
  platforms.create(2246, 450, 'groundBrownGreenUpCurlyUpLeft')
  platforms.create(2310, 638, 'groundBrown')
  platforms.create(2310, 574, 'groundBrown')
  platforms.create(2310, 514, 'groundBrown')
  platforms.create(2310, 450, 'groundBrownGreenUp')
  platforms.create(2372, 638, 'groundBrown')
  platforms.create(2372, 574, 'groundBrown')
  platforms.create(2372, 514, 'groundBrownCornerRight')
  platforms.create(2372, 450, 'groundBrownGreenCurlyRight')
  // End of stairs
  platforms.create(2436, 638, 'groundBrown')
  platforms.create(2436, 574, 'groundBrown')
  platforms.create(2436, 514, 'groundBrownGreenUpCurlyUpLeft')
  platforms.create(2500, 638, 'groundBrown')
  platforms.create(2500, 574, 'groundBrown')
  platforms.create(2500, 514, 'groundBrownGreenUp')
  platforms.create(2562, 638, 'groundBrownCurlyRight')
  platforms.create(2562, 574, 'groundBrownCurlyRight')
  platforms.create(2562, 514, 'groundBrownGreenCurlyRight')

  // pl3 - ground
  platforms.create(2802, 638, 'groundBrownCurlyLeft')
  platforms.create(2802, 574, 'groundBrownGreenCurlyLeft')
  platforms.create(2864, 638, 'groundBrown')
  platforms.create(2864, 574, 'groundBrownGreenUp')
  platforms.create(2928, 638, 'groundBrown')
  platforms.create(2928, 574, 'groundBrownGreenUp')
  platforms.create(2992, 638, 'groundBrown')
  platforms.create(2992, 574, 'groundBrownGreenUp')
  platforms.create(3056, 638, 'groundBrown')
  platforms.create(3056, 574, 'groundBrownGreenUp')
  platforms.create(3120, 638, 'groundBrown')
  platforms.create(3120, 574, 'groundBrownGreenUp')
  platforms.create(3184, 638, 'groundBrown')
  platforms.create(3184, 574, 'groundBrownGreenUp')
  platforms.create(3248, 638, 'groundBrown')
  platforms.create(3248, 574, 'groundBrownGreenUp')
  platforms.create(3312, 638, 'groundBrown')
  platforms.create(3312, 574, 'groundBrownGreenUp') // Clef
  platforms.create(3376, 638, 'groundBrown')
  platforms.create(3376, 574, 'groundBrownGreenUp')
  platforms.create(3440, 638, 'groundBrown')
  platforms.create(3440, 574, 'groundBrownGreenUp')
  platforms.create(3504, 638, 'groundBrown')
  platforms.create(3504, 574, 'groundBrownGreenUp')
  platforms.create(3568, 638, 'groundBrown')
  platforms.create(3568, 574, 'groundBrownGreenUp')
  platforms.create(3632, 638, 'groundBrown')
  platforms.create(3632, 574, 'groundBrownGreenUp')
  platforms.create(3696, 638, 'groundBrown')
  platforms.create(3696, 574, 'groundBrownGreenUp')
  platforms.create(3760, 638, 'groundBrown')
  platforms.create(3760, 574, 'groundBrownGreenUp')
  platforms.create(3824, 638, 'groundBrown')
  platforms.create(3824, 574, 'groundBrownGreenUp')

  platforms.refresh()

  return platforms
}
